var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"elevation-0"},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.title)+" "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","hint":"Kode, Deskripsi (press ENTER to search)","label":"Search"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchOnEnter.apply(null, arguments)},"blur":_vm.searchOnEnter}})],1),_c('v-data-table',{staticClass:"elevation-0",attrs:{"single-select":!_vm.multiSelect,"show-select":_vm.multiSelect,"headers":_vm.headers,"items":_vm.fKurikulumsFiltered,"page":_vm.currentPage,"items-per-page":_vm.pageSize,"hide-default-footer":""},on:{"update:page":function($event){_vm.currentPage=$event},"page-count":function($event){_vm.totalTablePages =_vm.totalPaginationPages}},scopedSlots:_vm._u([{key:"item.fdivisionBean",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.lookupFDivision(item.fdivisionBean))+" ")]),_c('div',[_c('v-chip',{attrs:{"color":_vm.getColorStatusActive(item.statusActive),"dark":"","small":""}},[_vm._v(" "+_vm._s(item.statusActive==true?'Aktif': '-')+" ")])],1)]}},{key:"item.statusActive",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColorStatusActive(item.statusActive),"dark":"","small":""}},[_vm._v(" "+_vm._s(item.statusActive==true?'Aktif': '-')+" ")])]}},{key:"item.avatar",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{staticClass:"ma-1",attrs:{"size":"72px"}},[_c('v-img',{attrs:{"lazy-src":_vm.lookupImageUrlLazy(item),"src":_vm.lookupImageUrl(item),"alt":"avatar"}})],1)]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"subtitle-1"},[_vm._v(" "+_vm._s(item.description)+" ")]),(item.fperiodeBean)?_c('div',{staticClass:"subtitle-2 font-weight-light"},[_vm._v(" "+_vm._s(_vm.computedDateFormattedDatefns(_vm.lookupFPeriode(item.fperiodeBean).periodeFrom ))+" S.D "),_c('div',[_vm._v(" "+_vm._s(_vm.computedDateFormattedDatefns(_vm.lookupFPeriode(item.fperiodeBean).periodeTo ))+" ")])]):_vm._e()]}},{key:"item.kuotaMale",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('v-icon',[_vm._v("mdi-human-male")]),_vm._v(" "+_vm._s(_vm.lookupFtKrs(item.id, true).length)+" "),_c('span',{staticClass:"ml-1 mr-1 caption grey--text"},[_vm._v("dari")]),_vm._v(" "+_vm._s(item.kuotaMale)+" ")],1),_c('div',[_c('v-icon',{attrs:{"color":"pink"}},[_vm._v("mdi-human-female")]),_vm._v(" "+_vm._s(_vm.lookupFtKrs(item.id, false).length)+" "),_c('span',{staticClass:"ml-1 mr-1 caption grey--text"},[_vm._v("dari")]),_vm._v(" "+_vm._s(item.kuotaFemale)+" ")],1)]}},{key:"item.amountBiaya",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.formattedCurrencyValue('', item.amountBiaya))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"disabled":_vm.multiSelect,"color":"primary","small":""},on:{"click":function($event){return _vm.showDialogEdit(item)}}},[_vm._v(" VALIDASI ")])]}}],null,true),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}}),_c('v-container',[_c('v-row',{attrs:{"justify":"end","align":"center"}},[_c('v-col',{attrs:{"cols":"4","md":"2","sm":"2"}},[_c('v-select',{attrs:{"items":_vm.pageSizes,"label":"Items per page"},model:{value:(_vm.pageSize),callback:function ($$v) {_vm.pageSize=$$v},expression:"pageSize"}})],1),_c('v-col',{attrs:{"cols":"10","md":"9","sm":"8","align":"right"}},[_c('v-pagination',{attrs:{"length":_vm.totalPaginationPages,"total-visible":"8","circle":""},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1),_c('FKurikulumDialog',{ref:"refFormDialog",attrs:{"formMode":_vm.formMode,"fKurikulums":_vm.fKurikulums,"itemsFPeriode":_vm.itemsFPeriode},on:{"eventFromFormDialogEdit":_vm.saveDataEdit}}),_c('v-snackbar',{scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackBarMesage)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }