<template>
  <v-card class="elevation-0">
    <v-card-title>
      {{ title }}
      <v-spacer></v-spacer>
        <v-text-field
            v-on:keyup.enter="searchOnEnter"
            v-on:blur="searchOnEnter"
            append-icon="mdi-magnify"
            hint="Kode, Deskripsi (press ENTER to search)"
            label="Search"
        ></v-text-field>


    </v-card-title>

    <v-data-table
        v-model="selectedItems"
        :single-select="!multiSelect"
        :show-select="multiSelect"
        :headers="headers"
        :items="fKurikulumsFiltered"
        :page.sync="currentPage"
        :items-per-page="pageSize"
        hide-default-footer
        class="elevation-0"
        @page-count="totalTablePages =totalPaginationPages "
    >


      <template v-slot:[`item.fdivisionBean`]="{ item }">
        <div class="caption">
          {{ lookupFDivision(item.fdivisionBean) }}
        </div>
        <div>
          <v-chip
              :color="getColorStatusActive(item.statusActive)"
              dark
              small
          >
            {{ item.statusActive==true?'Aktif': '-' }}
          </v-chip>

        </div>
      </template>
      <template v-slot:[`item.statusActive`]="{ item }">
        <v-chip
            :color="getColorStatusActive(item.statusActive)"
            dark
            small
        >
          {{ item.statusActive==true?'Aktif': '-' }}
        </v-chip>
      </template>

      <template v-slot:[`item.avatar`]="{ item}">
        <v-avatar
            size="72px"
            class="ma-1"
        >
          <v-img
              :lazy-src="lookupImageUrlLazy(item)"
              :src="lookupImageUrl(item)"
              alt="avatar"
          >
          </v-img>
        </v-avatar>
      </template>

      <template v-slot:[`item.description`]="{ item }">
        <div class="subtitle-1">
          {{item.description}}
        </div>
        <div class="subtitle-2 font-weight-light" v-if="item.fperiodeBean">
          {{ computedDateFormattedDatefns(lookupFPeriode(item.fperiodeBean).periodeFrom ) }}
          S.D
          <div>
            {{ computedDateFormattedDatefns(lookupFPeriode(item.fperiodeBean).periodeTo ) }}
          </div>
        </div>
      </template>

      <template v-slot:[`item.kuotaMale`]="{ item }">
        <div>
          <v-icon>mdi-human-male</v-icon>
          {{ lookupFtKrs(item.id, true).length }}
          <span class="ml-1 mr-1 caption grey--text">dari</span>  {{ item.kuotaMale }}
        </div>
        <div>
          <v-icon color="pink">mdi-human-female</v-icon>
          {{ lookupFtKrs(item.id, false).length }}
          <span class="ml-1 mr-1 caption grey--text">dari</span>  {{ item.kuotaFemale }}
        </div>

      </template>

      <template v-slot:[`item.amountBiaya`]="{ item }">
        <div>
          {{ formattedCurrencyValue('', item.amountBiaya)}}
        </div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
            @click="showDialogEdit(item)"
            :disabled="multiSelect"
            color="primary"
            small
        >
          VALIDASI
        </v-btn>
      </template>

    </v-data-table>

    <v-container>
      <v-row justify="end" align="center">
        <v-col
        cols="4"
        md="2"
        sm="2"
        >
          <v-select
              v-model="pageSize"
              :items="pageSizes"
              label="Items per page"
          ></v-select>
        </v-col>
        <v-col
            cols="10"
            md="9"
            sm="8"
            align="right"
        >
          <v-pagination
              v-model="currentPage"
              :length="totalPaginationPages"
              total-visible="8"
              circle
          ></v-pagination>
        </v-col>
      </v-row>
    </v-container>

    <FKurikulumDialog
        :formMode="formMode"
        :fKurikulums="fKurikulums"
        :itemsFPeriode="itemsFPeriode"
                  ref="refFormDialog"
                  @eventFromFormDialogEdit="saveDataEdit"
    ></FKurikulumDialog>

    <v-snackbar
        v-model="snackbar"
    >
      {{ snackBarMesage }}
      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

  </v-card>
</template>

<script>
import FKurikulumService from '@/services/apiservices/f-kurikulum-service';
import FDivisionService from "@/services/apiservices/f-division-service";
import FtKrsService from "../../../services/apiservices/ft-krs-service"

import FKurikulumDialog from "./FValidasiDialog";
import FormMode from "@/models/form-mode";
import FKurikulum from '@/models/f-periode'
import FileService from "@/services/apiservices/file-service";
import {format, parseISO} from "date-fns";
import FPeriodeService from "@/services/apiservices/f-periode-service";

export default {
  components: { FKurikulumDialog},
  data () {
    return {
      title: 'VALIDASI PPDB/KRS',
      snackbar: false,
      snackBarMesage: '',

      multiSelect: false,
      selectedItems: [],

      currentPage: 1,
      totalTablePages: 1,
      totalPaginationPages: 1,
      pageSize: 10,
      pageSizes: [10, 25, 50, 150, 500],

      search: '',
      headers: [
        {
          text: 'avatar',
          align: 'center',
          sortable: false,
          value: 'avatar',
        },
        { text: 'Kurikulum', value: 'description', width:"25%" },
        { text: 'Kuota Terpenuhi', value: 'kuotaMale' },
        { text: 'Biaya', value: 'amountBiaya' },
        { text: 'Unit', value: 'fdivisionBean' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],

      formMode: '',
      itemSelectedIndex: -1,
      itemSelected: '',
      fKurikulums:[],
      itemsFtKrs:[],
      itemsFDivision: [],
      itemsFPeriode: [],

    }
  },
  watch: {
    currentPage: {
      handler: function (value) {
        if (value) this.fetchFKurikulum()
      }
    },
    pageSize: {
      handler: function (value) {
        const refreshData = (this.currentPage == 1)
        this.currentPage = 1
        if (refreshData) {
          console.log("Change PageSize " + value)

          this.fetchFKurikulum()

        }
        // console.log("page size = " + value + " >> " + this.totalPages)
      }
    },
  },

  computed: {
    currentUser(){
      return this.$store.state.auth.user;
    },
    fKurikulumsFiltered(){
      return this.fKurikulums
    }
  },
  methods: {
    searchOnEnter(event){
      if (this.search !== event.target.value) {
        this.currentPage = 1
        this.search = event.target.value
        this.fetchFKurikulum()
      }
    },
    fetchParent(){
      if (this.currentUser.organizationLevel === "CORP" || this.currentUser.organizationLevel === "SYS" ) {
        FDivisionService.getAllFDivision().then(
            response => {
              // console.log(response.data)
              this.itemsFDivision = response.data
            },
            error => {
              console.log(error.response)
            }
        )
      }else {
        FDivisionService.getFDivisionById(this.currentUser.fdivisionBean).then(
            response=>{
              this.itemsFDivision = [response.data]
              console.log(response.data.items)
            },
            error=>{
              console.log(error.response)
            }
        )
      }

      FPeriodeService.getAllFPeriode().then(
          response =>{
            this.itemsFPeriode = response.data
          }
      )
    },
    fetchFKurikulum() {

      FKurikulumService.getAllFKurikulumContaining(this.currentPage, this.pageSize, "id", "DESC", this.search).then(
          response => {
            console.log(response.data.items)
            const { items, totalPages} = response.data
            this.fKurikulums = items
            this.totalPaginationPages = totalPages
            console.log(`TotalPage ${totalPages} and TotalItems ${items} `)

            // console.log(JSON.stringify(this.fKurikulums))

            const fkurikulumIds = []
            items.forEach( item => {

                // console.log(JSON.stringify(item.id))

                const id =JSON.stringify(item.id)
                fkurikulumIds.push(id)
              }
            )
            FtKrsService.getAllFtKrsByFkurikulumsPublic(fkurikulumIds).then(
                response=>{
                  this.itemsFtKrs = response.data
                },
                error =>{
                  console.log(error)
                }
            )


          },
          error => {
            console.log(error.response)
            if (error.response.status === 401){
              this.snackBarMesage = "Anda tidak mempunyai Akses!!"
              this.snackbar = true
            }
          }
      )

    },
    linkNeated(link){
      return link.replace(/\s+/g, '-').toLocaleLowerCase()
    },

    showDialogEdit (item) {
      this.itemSelectedIndex = this.fKurikulumsFiltered.indexOf(item)
      const itemModified = Object.assign({}, item)


      // this.formDialogShow = true
      this.formMode = FormMode.EDIT_FORM
      this.$refs.refFormDialog.showDialog(this.itemSelectedIndex, itemModified, this.itemsFDivision)

      // console.log('Hello masuk sini')

    },

    saveDataEdit(item){
        this.itemSelected = item
        Object.assign(this.fKurikulums[this.itemSelectedIndex], this.itemSelected)
        this.closeDialog()
    },

    closeDialog () {
      // this.myConfirmDialog = false
      this.formMode = ''
      this.$refs.refDeleteConfirmDialog.setDialogState(false)
      this.$refs.refFormDialog.setDialogState(false)

      this.$nextTick(() => {
        this.itemSelected = Object.assign({}, new FKurikulum())
        this.itemSelectedIndex = -1
      })
    },

    getColorStatusActive (trueFalse) {
      if (trueFalse === true) return 'green'
      else if (trueFalse === false) return 'gray'
      else return 'gray'
    },

    lookupFDivision (fdivisionBean) {
      const str = this.itemsFDivision.filter(x => x.id==fdivisionBean)
      if (str.length>0){
        return str[0].description
      }else {
        return '-'
      }
    },
    lookupFPeriode (fperiodeBean) {
      const str = this.itemsFPeriode.filter(x => x.id==fperiodeBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },
    lookupFtKrs (fkurikulumBean, laki) {
      return  this.itemsFtKrs.filter(x => x.fkurikulumBean === fkurikulumBean && x.fsiswaRes.sex ===laki && x.enumStatApproval==='APPROVE')
    },

    computedDateFormattedDatefns (value) {
      return value ? format(parseISO(value), 'dd-MMM-yyyy') : ''
    },
    formattedCurrencyValue(prefix, value){
      if(! value){ return "0"}
      return prefix + "" + parseFloat(value).toLocaleString()
    },

    lookupImageUrl(item){
      if (item.avatarImage===undefined || item.avatarImage===""){
        return './assets/images/no_image_available.jpeg'
      }else {
        return FileService.image_url_medium(item.avatarImage)
      }
    },
    lookupImageUrlLazy(){
      return './assets/images/no_image_available.jpeg'
    },

  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push('/login')
    }else {
      this.fetchParent()
      this.fetchFKurikulum()

    }
  }

}
</script>

<style scoped>

</style>