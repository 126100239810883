
const ESchemaKrs = Object.freeze([

    {
        column: 'Nomor Pendaftaran',
        type: String,
        value: item => item.kode1
    },
    {
        column: 'STATUS',
        type: String,
        value: item => item.enumStatApproval
    },

    {
        column: 'NAMA SISWA',
        type: String,
        width:35,
        value: item => item.fsiswaRes.fullName
    },

    {
        column: 'Jenis Kelamin',
        type: String,
        value: item => `${item.fsiswaRes.sex===true?'Laki-Laki': 'Perempuan'}`
    },

    {
        column: 'Alamat',
        type: String,
        width: 35,
        value: item => item.fsiswaRes.address1
    },

    {
        column: 'Kecamatan',
        type: String,
        value: item => item.fsiswaRes.address3
    },
    {
        column: 'Kota',
        type: String,
        value: item => item.fsiswaRes.city
    },
    {
        column: 'Propinsi',
        type: String,
        value: item => item.fsiswaRes.state
    },
    {
        column: 'Nomor HP',
        type: String,
        width: 20,
        value: item => item.fsiswaRes.phone
    },
    {
        column: 'Nama Ayah',
        type: String,
        width: 35,
        value: item => item.fsiswaRes.fatherName
    },
    {
        column: 'Nama Ibu',
        type: String,
        value: item => item.fsiswaRes.motherName
    },
    {
        column: 'Alamat',
        type: String,
        width: 35,
        value: item => `${item.fsiswaRes.parAddress1} - ${item.fsiswaRes.parCity} - ${item.fsiswaRes.parState}`
    },
    {
        column: 'Kyai Kelompok',
        type: String,
        value: item => item.fsiswaRes.kiKelompok
    },
    {
        column: 'Kelompok',
        type: String,
        value: item => item.fsiswaRes.kelompokSmbg
    },
    {
        column: 'Desa',
        type: String,
        value: item => item.fsiswaRes.desaSmbg
    },
    {
        column: 'Daerah',
        type: String,
        width: 20,
        value: item => item.fsiswaRes.daerahSmbg
    },
    {
        column: 'Telpon Kyai',
        type: String,
        value: item => item.fsiswaRes.telpKiKelompok
    }


])


export default ESchemaKrs