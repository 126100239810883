<template>
  <v-dialog
      v-model="dialogShow"
      :max-width="myConfirmCloseOptions.width"
      :style="{ zIndex: myConfirmCloseOptions.zIndex }"
      @keydown.esc="dialogShow=false"
  >
    <v-card>
      <v-toolbar dark :color="color" dense flat>
        <v-toolbar-title class="text-body-2 font-weight-bold grey--text">
          {{ title }}
        </v-toolbar-title>
      </v-toolbar>
        <p align="center" class="mt-2">
          {{message1}}<br>{{message2}}
        </p>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey darken-1" outlined text @click="dialogShow=false">Cancel</v-btn>
        <v-btn color="blue darken-1" outlined text @click="confirmed" ><v-icon small class="mr-1">mdi-check</v-icon>Oke</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>

</template>

<script>
export default {
  data() {
    return {
      dialogShow: false,
      myConfirmCloseOptions: {
        width: 400,
        zIndex: 200,
        noconfirm: false,
      },
      title: 'Konfirmasi',
      message1: 'Lanjutkan Proses?',
      message2: '',
      color: "grey lighten-3",

    }
  },

  props: [
  ],
  computed: {
  },
  methods: {
    confirmed: function(){
      this.$emit('eventFromMyConfirm', "OKE")
      this.dialogShow = false
    },
    showDialog(title, message1, message2, color) {
      // console.log("from parent " + message)
      this.title = title!==''?title:''
      this.message1 = message1!==''?message1:''
      this.message2 = message2!==''?message2:''
      this.color = color!==''?color:''
      this.dialogShow = !this.dialogShow
    },
    setDialogState(value){
      console.log(value)
      this.dialogShow =false
    }


  }

}
</script>

<style scoped>

</style>