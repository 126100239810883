<template>
  <div>
    <v-dialog
      v-model="dialogShow"
      @keydown.esc.prevent="closeForm"
      persistent
      hide-overlay
      transition="dialog-bottom-transition"

    >

     <v-card>
       <v-toolbar
           dark
           color="white"
       >
         <v-btn
             icon
             dark
             @click="closeForm"
         >
           <v-icon>mdi-arrow-left</v-icon>
         </v-btn>
         <v-toolbar-title>{{ title }}</v-toolbar-title>

         <v-spacer></v-spacer>
         <v-btn
             icon
             dark
             color="red"
             @click="closeForm"
         >
           <v-icon>mdi-close</v-icon>
         </v-btn>
       </v-toolbar>
       <v-card-title></v-card-title>

       <v-card-text>
        <v-row>
          <v-col cols="12" sm="5" md="5">
            <v-img :lazy-src="lookupImageUrlLazy(itemModified.fsiswaRes)" :src="lookupImageUrl(itemModified.fsiswaRes)">
            </v-img>
          </v-col>
          <v-col cols="12" sm="6" md="6" class="ml-4">
            <div class="mt-2">
              <v-flex class="mt-4 font-weight-bold">INFO PRIBADI</v-flex>
              <v-divider></v-divider>
              <v-flex class="d-flex subtitle-1"> {{ itemModified.fsiswaRes.fullName }} </v-flex>
              <v-flex  class="d-flex"> {{ itemModified.fsiswaRes.address1 }} {{ itemModified.fsiswaRes.address2 }}  </v-flex>
              <v-flex class="d-flex"> {{ itemModified.fsiswaRes.city }} {{ itemModified.fsiswaRes.state }}  </v-flex>
              <v-flex class="d-flex"> <v-icon small v-if="itemModified.fsiswaRes.phone" class="mr-1">mdi-phone-classic</v-icon> {{ itemModified.fsiswaRes.phone }} </v-flex>

              <v-flex class="mt-4 font-weight-bold">ORANG TUA</v-flex>
              <v-divider></v-divider>
              <v-flex class="d-flex">Bapak: {{ itemModified.fsiswaRes.fatherName }} - {{ itemModified.fsiswaRes.fatherJob }} </v-flex>
              <v-flex class="d-flex">Ibu: {{ itemModified.fsiswaRes.motherName }} - {{ itemModified.fsiswaRes.motherJob }} </v-flex>
              <v-flex class="d-flex">Alamat: {{ itemModified.fsiswaRes.parCity }} <v-icon small v-if="itemModified.fsiswaRes.fatherPhone" class="ml-2 mr-1">mdi-phone-classic</v-icon> {{ itemModified.fsiswaRes.fatherPhone }}  </v-flex>

              <v-flex class="mt-4 font-weight-bold">SAMBUNG</v-flex>
              <v-divider></v-divider>
              <v-flex class="d-flex">Sambung: <span class="font-weight-italic ml-1">{{ itemModified.fsiswaRes.kelompokSmbg }} - {{ itemModified.fsiswaRes.desaSmbg }}</span> - {{ itemModified.fsiswaRes.daerahSmbg }}  </v-flex>
              <v-flex class="d-flex">Kyai Kelompok: <span class="font-weight-bold ml-1">{{ itemModified.fsiswaRes.kiKelompok }}</span> - <v-icon small v-if="itemModified.fsiswaRes.telpKiKelompok" class="mr-1">mdi-phone-classic</v-icon> {{ itemModified.fsiswaRes.telpKiKelompok }}  </v-flex>
            </div>

            <div>
              <v-flex class="mt-6 font-weight-bold"></v-flex>
              <v-divider></v-divider>
              <v-flex class="subtitle-2 font-italic">Pendaftaran #{{ itemModified.kode1 }} </v-flex>
              <v-flex>
                <v-btn class="ma-4" color="green" @click="setuju">Setuju</v-btn>
                <v-btn color="red" @click="menolak">Tidak Setuju</v-btn>
              </v-flex>
            </div>

          </v-col>

        </v-row>
       </v-card-text>
     </v-card>


    </v-dialog>

    <MyConfirmDialog
        ref="refMyConfirmDialogSetuju"
        @eventFromMyConfirm="setujuConfirmed"
    ></MyConfirmDialog>
    <MyConfirmDialog
        ref="refMyConfirmDialogMenolak"
        @eventFromMyConfirm="menolakConfirm"
    ></MyConfirmDialog>

  </div>

</template>

<script>

import { format, parseISO } from 'date-fns'
import FileService from "@/services/apiservices/file-service";
import FtKrs from "@/models/ft-krs";
import MyConfirmDialog from "@/components/utils/MyConfirmDialog";


export default {
  components: {
    MyConfirmDialog
  },
  props:{
    formMode: String,
  },
  data() {
    return {
      title: 'Validasi PPDB',
      dialogShow: false,

      valid: false,
      itemModified: new FtKrs(),

    }
  },
  computed: {
    currentUser(){
      return this.$store.state.auth.user;
    },
    fKurikulumsFiltered(){
      console.log(this.fKurikulums)
      return this.fKurikulums
    }
  },

  watch: {
  },

  methods: {
    searchOnEnter(event){
      if (this.search !== event.target.value) {
        this.currentPage = 1
        this.search = event.target.value
        this.fetchFKurikulum()
      }
    },
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    },
    computedDateFormattedDatefns (value) {
      return value ? format(parseISO(value), 'dd-MMM-yyyy') : ''
    },

    showDialog(selectedIndex, item){
      this.dialogShow = !this.dialogShow
      this.selectedIndex = selectedIndex
      // this.initializeEditMode(item)
      this.itemModified = item
    },
    setDialogState(value){
      this.dialogShow =value
    },


    closeForm(){
      this.dialogShow = false
      this.$emit('eventFromFormDialog1', this.itemModified)

    },
    passingEventFromCloseConfirm(value){
      if (value==='OKE') this.dialogShow = false
    },

    getColorStatusActive (trueFalse) {
      if (trueFalse === true) return 'green'
      else if (trueFalse === false) return 'gray'
      else return 'gray'
    },



    setuju(){
      this.$refs.refMyConfirmDialogSetuju.showDialog("Konfirmasi", "Diterima?", "", "green lighten-5")
    },

    setujuConfirmed(valueOKE){
      if (valueOKE==="OKE"){
        // console.log("SETUJU")
        this.dialogShow = !this.dialogShow
        this.$emit('eventKonfirmasi', 'SETUJU')
      }
    },

    menolak(){
      this.$refs.refMyConfirmDialogMenolak.showDialog("Konfirmasi", "Menolak?", "", "red lighten-3")
    },

    menolakConfirm(valueOKE){
      if (valueOKE==='OKE'){
        // console.log("MENOLAK")
        this.dialogShow = !this.dialogShow
        this.$emit('eventKonfirmasi', 'MENOLAK')
      }
    },


    lookupImageUrl(item){
      if (item.avatarImage===undefined || item.avatarImage===""){
        return './assets/images/account_icon.png'
      }else {
        return FileService.image_url_medium(item.avatarImage)
      }
    },
    lookupImageUrlLazy(){
      return './assets/images/account_icon.png'
    },

  }

}
</script>

<style scoped>
</style>