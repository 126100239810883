var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"persistent":"","fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }$event.preventDefault();return _vm.closeForm.apply(null, arguments)}},model:{value:(_vm.dialogShow),callback:function ($$v) {_vm.dialogShow=$$v},expression:"dialogShow"}},[_c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}}),(_vm.formMode)?_c('div'):_vm._e(),_c('v-card',[_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-toolbar',{staticClass:"color-gradient-1",attrs:{"dark":"","color":"brown"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":_vm.closeForm}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.title))]),_c('span',{staticClass:"ml-2 mr-2 font-weight-medium grey--text"},[_vm._v(" | ")]),(_vm.formMode === 'EDIT_FORM' )?_c('span',[_vm._v("EDIT ")]):_vm._e(),_c('span',{staticClass:"font-weight-light ml-1 mr-1"},[_vm._v("ITEM")]),(_vm.formMode === 'NEW_FORM' )?_c('span',[_vm._v(" BARU")]):_vm._e(),_c('v-chip',{staticClass:"ma-2",attrs:{"color":"warning","outlined":"","x-small":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-pencil ")]),_vm._v(" modfied ")],1),_c('v-spacer')],1),_c('v-card-text',[_c('v-container',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.title)+" "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","hint":"Kode, Deskripsi (press ENTER to search)","label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-menu',{staticStyle:{"align-items":"start"},attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"fab":"","dark":"","x-small":"","color":"blue"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-view-grid")])],1)]}}])},[_c('v-list',{attrs:{"color":"grey lighten-4"}},[_c('v-list-item',[_c('v-btn',{attrs:{"plain":"","elevation":"0","color":"gray darken-1","small":""},on:{"click":_vm.exportToExcel}},[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-file-excel")]),_vm._v(" Export Excel ")],1)],1)],1)],1)],1),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.ftKrsesFiltered,"search":_vm.search,"items-per-page":_vm.pageSize,"page":_vm.currentPage},on:{"update:page":function($event){_vm.currentPage=$event}},scopedSlots:_vm._u([{key:"item.fdivisionBean",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.lookupFDivision(item.fdivisionBean))+" ")]),_c('div',[_c('v-chip',{attrs:{"color":_vm.getColorStatusActive(item.statusActive),"dark":"","small":""}},[_vm._v(" "+_vm._s(item.statusActive==true?'Aktif': '-')+" ")])],1)]}},{key:"item.statusActive",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColorStatusActive(item.statusActive),"dark":"","small":""}},[_vm._v(" "+_vm._s(item.statusActive==true?'Aktif': '-')+" ")])]}},{key:"item.avatar",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{staticClass:"ma-1",attrs:{"size":"72px"}},[_c('v-img',{attrs:{"lazy-src":_vm.lookupImageUrlLazy(item.fsiswaRes),"src":_vm.lookupImageUrl(item.fsiswaRes),"alt":"avatar"}})],1)]}},{key:"item.city",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"fill-height"},[_c('div',{staticClass:"caption"},[_vm._v(" "+_vm._s(item.fsiswaRes.address1)+" "+_vm._s(item.fsiswaRes.address2)+" ")]),_c('div',{staticClass:"caption"},[_vm._v(" "+_vm._s(item.fsiswaRes.city)+" - "+_vm._s(item.fsiswaRes.state)+" ")]),(item.phone)?_c('div',{staticClass:"caption"},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-phone-classic")]),_vm._v(_vm._s(item.phone)+" ")],1):_vm._e()])]}},{key:"item.enumStatApproval",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"white--text",attrs:{"color":_vm.lookupColorStatus(item.enumStatApproval),"x-small":"","outlined":""}},[(item.enumStatApproval==='APPROVE' )?_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-check")]):_vm._e(),_vm._v(" "+_vm._s(item.enumStatApproval)+" ")],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"white--text",attrs:{"color":"green","small":""},on:{"click":function($event){return _vm.showDialogEdit(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil-outline")])],1)]}}],null,true)})],1)],1),_c('v-card-actions',[_c('v-chip',{directives:[{name:"show",rawName:"v-show",value:(_vm.formDialogOptions.errorMessage),expression:"formDialogOptions.errorMessage"}],staticClass:"ml-4",attrs:{"color":"error","outlined":"","close":""}},[_vm._v(" "+_vm._s(_vm.formDialogOptions.errorMessage)+" ")]),_c('v-spacer'),_c('v-btn',{staticClass:"hidden-sm-and-down",attrs:{"color":"blue darken-1","outlined":"","text":""},on:{"click":_vm.closeForm}},[_vm._v(" Tutup ")])],1)],1)],1),_c('CloseConfirmDialog',{ref:"refCloseConfirmDialog",on:{"eventFromCloseConfirm":_vm.passingEventFromCloseConfirm}}),_c('FValidasiAcceptDialog',{ref:"refFormAcceptDialog",on:{"eventKonfirmasi":_vm.eventKonfirmasi}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }