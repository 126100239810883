<template>
  <div>
    <v-dialog
      v-model="dialogShow"
      @keydown.esc.prevent="closeForm"
      persistent
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >

      <v-form v-model="valid"></v-form>
      <div v-if="formMode"></div>
      <v-card>
        <v-form v-model="valid" ref="form">

          <v-toolbar
            dark
            color="brown"
            class="color-gradient-1"
          >
            <v-btn
                icon
                dark
                @click="closeForm"
            >
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-toolbar-title>{{ title }}</v-toolbar-title>

            <span class="ml-2 mr-2 font-weight-medium grey--text"> | </span>
            <span v-if="formMode === 'EDIT_FORM' ">EDIT </span>
            <span class="font-weight-light ml-1 mr-1">ITEM</span>
            <span v-if="formMode === 'NEW_FORM' "> BARU</span>

            <v-chip
                class="ma-2"
                color="warning"
                outlined
                x-small
            >
              <v-icon left>
                mdi-pencil
              </v-icon>
              modfied
            </v-chip>

            <v-spacer></v-spacer>

          </v-toolbar>

          <v-card-text>

            <v-container>

              <v-card-title>
                {{ title }}
                <v-spacer></v-spacer>
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    hint="Kode, Deskripsi (press ENTER to search)"
                    label="Search"
                ></v-text-field>

                <v-menu offset-y style="align-items: start">
                  <template v-slot:activator="{ on, attrs }" >
                    <v-btn
                        class="ml-2"
                        fab
                        dark
                        x-small
                        color="blue"
                        v-bind="attrs"
                        v-on="on"
                    >
                      <v-icon>mdi-view-grid</v-icon>
                    </v-btn>

                  </template>
                  <v-list color="grey lighten-4" >
                    <v-list-item>
                      <v-btn
                          plain
                          elevation="0"
                          color="gray darken-1"
                          small
                          @click="exportToExcel"
                      >

                        <v-icon color="green">mdi-file-excel</v-icon>
                        Export Excel

                      </v-btn>
                    </v-list-item>
                  </v-list>

                </v-menu>

              </v-card-title>

              <v-data-table
                  :headers="headers"
                  :items="ftKrsesFiltered"
                  :search="search"
                  :items-per-page="pageSize"
                  :page.sync="currentPage"
                  class="elevation-0"
              >


                <template v-slot:[`item.fdivisionBean`]="{ item }">
                  <div class="caption">
                    {{ lookupFDivision(item.fdivisionBean) }}
                  </div>
                  <div>
                    <v-chip
                        :color="getColorStatusActive(item.statusActive)"
                        dark
                        small
                    >
                      {{ item.statusActive==true?'Aktif': '-' }}
                    </v-chip>

                  </div>
                </template>
                <template v-slot:[`item.statusActive`]="{ item }">
                  <v-chip
                      :color="getColorStatusActive(item.statusActive)"
                      dark
                      small
                  >
                    {{ item.statusActive==true?'Aktif': '-' }}
                  </v-chip>
                </template>

                <template v-slot:[`item.avatar`]="{ item}">
                  <v-avatar
                      size="72px"
                      class="ma-1"
                  >
                    <v-img
                        :lazy-src="lookupImageUrlLazy(item.fsiswaRes)"
                        :src="lookupImageUrl(item.fsiswaRes)"
                        alt="avatar"
                    >
                    </v-img>
                  </v-avatar>
                </template>

<!--                <template v-slot:[`item.fullName`]="{ item }">-->
<!--                  <div class="fill-height">-->
<!--                    <div class="subtitle-1">-->
<!--                      {{item.fsiswaRes.fullName}}-->
<!--                    </div>-->
<!--                    <div class="subtitle-2 mt-1 grey&#45;&#45;text">-->
<!--                      #{{item.kode1}}-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </template>-->

                <template v-slot:[`item.city`]="{ item }">
                  <div class="fill-height">
                    <div class="caption">
                      {{item.fsiswaRes.address1}} {{item.fsiswaRes.address2}}
                    </div>
                    <div class="caption">
                      {{item.fsiswaRes.city}} - {{item.fsiswaRes.state}}
                    </div>
                    <div class="caption" v-if="item.phone">
                      <v-icon x-small>mdi-phone-classic</v-icon>{{item.phone}}
                    </div>

                  </div>
                </template>


                <template v-slot:[`item.enumStatApproval`]="{ item }">
                  <v-btn
                      :color="lookupColorStatus(item.enumStatApproval)"
                      class="white--text"
                      x-small
                      outlined
                  >
                    <v-icon small v-if="item.enumStatApproval==='APPROVE' ">mdi-check</v-icon>
                    {{ item.enumStatApproval }}
                  </v-btn>
                </template>

                <template v-slot:[`item.actions`]="{ item }">
                  <v-btn
                      @click="showDialogEdit(item)"
                      color="green"
                      class="white--text"
                      small
                  >
                    <v-icon small>mdi-pencil-outline</v-icon>
                  </v-btn>
                </template>

              </v-data-table>


            </v-container>

          </v-card-text>

          <v-card-actions>
            <v-chip
                class="ml-4"
                color="error"
                outlined
                close
                v-show="formDialogOptions.errorMessage"
            >
              {{formDialogOptions.errorMessage}}
            </v-chip>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                outlined
                text
                @click="closeForm"
                class="hidden-sm-and-down"
            >
              Tutup
            </v-btn>

          </v-card-actions>
        </v-form>

      </v-card>

      <CloseConfirmDialog
        ref="refCloseConfirmDialog"
        @eventFromCloseConfirm="passingEventFromCloseConfirm"
      ></CloseConfirmDialog>

      <FValidasiAcceptDialog
          ref="refFormAcceptDialog"
          @eventKonfirmasi="eventKonfirmasi"
      ></FValidasiAcceptDialog>
    </v-dialog>

  </div>

</template>

<script>
import FtKrsService from '../../../services/apiservices/ft-krs-service';


import CloseConfirmDialog from "../../../components/utils/CloseConfirmDialog";
import { format, parseISO } from 'date-fns'
import FileService from "@/services/apiservices/file-service";
import FValidasiAcceptDialog from "@/components/ppdb/validasi/FValidasiAcceptDialog";
import FtKrs from "@/models/ft-krs";

import writeXlsxFile from 'write-excel-file'
import FileSaver from 'file-saver'
import ESchemaKrs from "@/models/e-schema-krs";

export default {
  components: {
    FValidasiAcceptDialog,
    CloseConfirmDialog
  },
  props:{
    formMode: String,
    itemsFPeriode: []
  },
  data() {
    return {
      title: 'Validasi PPDB',

      dialogShow: false,
      formDialogOptions: {
        color: "grey lighten-3",
        width:800,
        zIndex: 200,
        noconfirm: false,
      },

      currentPage: 1,
      totalTablePages: 1,
      totalPaginationPages: 1,
      pageSize: 10,
      pageSizes: [10, 25, 50, 150, 500],

      search: '',
      headers: [
        {
          text: 'avatar',
          align: 'center',
          sortable: false,
          value: 'avatar',
        },
        { text: 'Siswa', value: 'fullName', width:"25%" },
        { text: 'Alamat', value: 'city' },
        { text: 'STATUS', value: 'enumStatApproval' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],

      ftKrses:[],
      selectedItemIndex: -1,
      itemDefault: '',
      itemModified: new FtKrs(),
      currentFkurikulum: '',
      itemsFDivision: [],

      valid: false,


    }
  },
  computed: {
    currentUser(){
      return this.$store.state.auth.user;
    },
    ftKrsesFiltered(){
      let items =[]
      this.ftKrses.forEach(
          item =>{
            item.fullName = item.fsiswaRes.fullName
            items.push(item)
          }
      )
      return items
    }

  },

  watch: {
  },

  methods: {

    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    },
    computedDateFormattedDatefns (value) {
      return value ? format(parseISO(value), 'dd-MMM-yyyy') : ''
    },

    showDialog(selectedIndex, item, itemsFDivision){
      this.dialogShow = !this.dialogShow

      this.selectedIndex = selectedIndex
      this.currentFkurikulum = item
      this.initializeEditMode(item)

      this.itemsFDivision = itemsFDivision

    },
    setDialogState(value){
      this.dialogShow =value
    },

    showDialogEdit (item) {
      this.itemSelectedIndex = this.ftKrsesFiltered.indexOf(item)
      const itemModified = Object.assign({}, item)
      this.itemModified = itemModified

      // this.formDialogShow = true
      this.$refs.refFormAcceptDialog.showDialog(this.itemSelectedIndex, itemModified)
    },

    eventKonfirmasi(value){
      console.log(`Konfirmasi ${value}`)

      if (value ==="SETUJU"){
        this.itemModified.enumStatApproval = "APPROVE"
      }else{
        this.itemModified.enumStatApproval = "REJECTED"
      }
      this.itemModified.approveDate = Date.now()
      this.itemModified.approveDate = Date.now()
      this.itemModified.approveBy = this.$store.state.auth.user.username

      FtKrsService.updateFtKrs(this.itemModified).then(
          ()=>{
            // console.log(JSON.stringify(response.data))
            this.initializeEditMode(this.currentFkurikulum)
          }
      )

    },

    closeForm(){
      this.dialogShow = false
      this.$emit('eventFromFormDialog1', this.itemModified)
    },
    passingEventFromCloseConfirm(value){
      if (value==='OKE') this.dialogShow = false
    },
    initializeEditMode(item){

      console.log(`Initialize mode ${item.id}`)

      FtKrsService.getAllFtKrsByFkurikulumId(item.id).then(
          response =>{
            // console.log(JSON.stringify(response.data))
            this.ftKrses = response.data
          }
      )

    },
    exportToExcel(){

      let schema = ESchemaKrs

      let fileBits = 'file_krs.xlsx'

      // let datas = []
      // this.ftKrses.forEach(item=>{
      //   datas.push(item)
      // })

      writeXlsxFile(this.ftKrses, {
        schema,
        fileName: fileBits
      })

      // let file = new File(["Hello, world!"], "hello world.txt", {type: "text/plain;charset=utf-8"});
      let file = new File(fileBits, "hello world.txt", {type: "xlsx/plain;charset=utf-8"});
      FileSaver.saveAs(file);
    },

    retrieveFiles(){
    },

    getColorStatusActive (trueFalse) {
      if (trueFalse === true) return 'green'
      else if (trueFalse === false) return 'gray'
      else return 'gray'
    },

    lookupFDivision (fdivisionBean) {
      const str = this.itemsFDivision.filter(x => x.id==fdivisionBean)
      if (str.length>0){
        return str[0].description
      }else {
        return '-'
      }
    },
    formattedCurrencyValue(prefix, value){
      if(! value){ return "0"}
      return prefix + "" + parseFloat(value).toLocaleString()
    },

    lookupImageUrl(item){
      if (item.avatarImage===undefined || item.avatarImage===""){
        return './assets/images/account_icon.png'
      }else {
        return FileService.image_url_medium(item.avatarImage)
      }
    },
    lookupImageUrlLazy(){
      return './assets/images/account_icon.png'
    },
    lookupColorStatus(enumApproval){
      var color = ''
      if (enumApproval ==='APPROVE'){
          color = 'green'
      }else if(enumApproval ==='REJECTED'){
        color = 'red'
      }else {
        color = 'grey'
      }
      return color
    }

  }

}
</script>

<style scoped>
</style>