<template>
  <v-card class="mt-2 ml-2 mr-2">
    <FValidasiTable/>
  </v-card>
</template>

<script>
import FValidasiTable from "../../components/ppdb/validasi/FValidasiTable";

export default {
  components: { FValidasiTable },
  data() {
    return {
    }
  },
  methods: {

  }
}
</script>

<style scoped>
</style>